<template>
  <div>
    <b-card>
      <!-- Alert: No item found -->
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Account Information') }}</span>
          </template>
          <tab-account-information
            class="mt-2 pt-75"
            :data="account"
          />
        </b-tab>
        

        <b-tab>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Basic') }}</span>
          </template>
          <tab-config-basic
            class="mt-2 pt-75"
            :data="account"
          />
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Center Configuration') }}</span>
          </template>
          
        </b-tab> -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="SettingsIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Terms And Conditions') }}</span>
            
          </template>
          <tabtnc
            class="mt-2 pt-75"
            :data="account"
            />
        </b-tab>
      </b-tabs>
      
      </b-card>
      <div class="pricing-free-trial" style="margin-top: 0rem;margin-bottom: 0rem;">
        <b-row>
            <b-col lg="10" offset-lg="3" class="mx-auto">
                <div class="pricing-trial-content d-flex justify-content-between">
                    <div class="text-center text-md-left mt-3">
                        <h3 class="text-primary">
                            遇到問題？
                        </h3>
                        <h5>可以即時聯絡我們， 我們會盡快幫你處理一切問題</h5>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-1 mt-lg-2" href="https://wa.me/85244464116" target="_blank">
                            <font-awesome-icon :icon="['fab', 'whatsapp']" /> &nbsp;&nbsp;按此 whatsapp 聯絡我們
                        </b-button>
                    </div>

                    <!-- images -->
                    <b-img fluid :src="require('@/assets/images/illustration/pricing-Illustration.svg')" class="pricing-trial-img" alt="svg img" />
                    <!--/ images -->
                </div>
            </b-col>
        </b-row>
    </div>
    </div>
  </template>
  
  <script>
  import {
    BAlert, BLink, BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTab, BTabs,
  } from 'bootstrap-vue'
  import { ref } from '@vue/composition-api'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import router from '@/router'
  import TabConfigBasic from './TabConfigBasic.vue'
  import Ripple from 'vue-ripple-directive'

  import TabAccountInformation from './TabAccountInformation.vue'
  import Tabtnc from './Tabtnc.vue'
  export default {
    components: {
      TabConfigBasic,
      TabAccountInformation,
      ToastificationContent,
      vSelect,
      flatPickr,
      Tabtnc,
      BForm,
      BFormGroup,
      BFormInput,
      BFormRadioGroup,
      BFormCheckboxGroup,
      BButton,
      BRow,
      BCol,
      BTab,
      BTabs,
      BCard,
      BAlert,
      BLink,
    },
    directives: {
        Ripple,
    },
    data() {
      return {
        client_module:JSON.parse(localStorage.getItem('userData')),
        account: null,
      }
    },
    created() {
      this.get_client_data()
    },
    methods: {
      get_client_data() {
        this.$http.get(process.env.VUE_APP_API_BASE+'/account/')
          .then(res => {
            this.account = res.data
          })
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>