<template>
<div>

    <b-row>
        <b-col md="8" lg="8">
            <div class="d-flex">
                <feather-icon icon="UserIcon" size="19" />
                <h4 class="mb-0 ml-50">
                    {{ $t('Account Information') }}
                </h4>
            </div>
            <!-- Form: Personal Info Form -->
            <b-form class="mt-1">
                <b-row>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Company / Client Logo 公司圖標 (400px x 132px)" label-for="member_code">
                            <b-form-file
                                id="extension"
                                accept=".jpg,.png"
                                v-model="data.account.new_logo"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Invoice Language 收據語言" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.invoice_language" disabled/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Invoice Type 收據類型" label-for="member_code">
                            <v-select :options="['PDF','PAPER']" v-model="data.account.invoice_type"  />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="收據自動發送" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.auto_send_pdf)"
                                    class="custom-control-success"
                                    v-model="data.account.auto_send_pdf"
                                    name="check-button"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                                </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Package Signature Package 必須簽名" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.member_package_need_sign)"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.member_package_need_sign"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                                </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="PT Session Lock Time (minutes) PT堂鎖定時間 (分鐘)" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.pt_lock_time" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Class Session Lock Time (minutes) 團體堂鎖定時間 (分鐘)" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.class_lock_time"  />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Schedule Time Step  (minutes) 時間表間隔時間  (分鐘)" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.calendar_minute_step"  />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Schedule First Day 時間表第一天" label-for="member_code">
                            <v-select :options="[
                                {label:'SUN 星期天',value:'0'},
                                {label:'MON 星期一',value:'1'},
                                {label:'TUE 星期二',value:'2'},
                                {label:'WED 星期三',value:'3'},
                                {label:'THU 星期四',value:'4'},
                                {label:'FRI 星期五',value:'5'},
                                {label:'SAT 星期六',value:'6'}
                                ]" 
                                v-model="data.account.calendar_first_day" 
                                :reduce="day => day.value"
                                :clearable="false"
                                />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="App Display Class Day 顯示團體班日子" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.app_display_day" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="App Delete Class Booking Limit 團體班預約刪除時間" label-for="member_code">
                            <b-form-input id="member_code" v-model="data.account.delete_class_limit" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Class Auto Attend 團體班自動出席" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.class_auto_attend)"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.class_auto_attend"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                                </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Dues Invoice Auto Send 月費收據自動發出" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.crontab_send_invoice)"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.crontab_send_invoice"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="PT Session Attend Message PT堂出席提示訊息" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.pt_attend_whatsapp)"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.pt_attend_whatsapp"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="PT Session Attend Message PT堂及團體堂上堂提示訊息" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="to_true_false(data.account.crontab_send_whatsapp)"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.crontab_send_whatsapp"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="12" lg="12">
                        <b-form-group label="Class Minimum Alert 團體堂最少人數到達提示" label-for="member_code">
                            <div>
                                <b-form-checkbox
                                    :checked="data.account.meet_min_alert"
                                    class="custom-control-success"
                                    name="check-button"
                                    v-model="data.account.meet_min_alert"
                                    switch
                                >
                                    <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2 mb-2">
                    <b-col>
                        <b-button :disabled="processing" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="save" v-if="$can('read', 'admin')">
                            Save Changes
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-col>
        <b-col lg="4">
            <img :src="data.account.logo" style="max-width:100%;" v-if="this.base64 == null">
            <img :src="base64" style="max-width:100%;" v-if="this.base64 != null">
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    BFormTextarea,
    BCardText,
    BAlert,
    BLink,
    BCard,
    BCardHeader,
    BFormFile,
    BCardBody,
    BMedia,
    BFormCheckbox,
    BAvatar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BTab,
    BTabs,
    BListGroup,
    BListGroupItem,
    BBadge
} from 'bootstrap-vue'
import {
    ref
} from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import moment from 'moment'


export default {
    components: {
        ToastificationContent,
        BFormTextarea,
        BFormCheckbox,
        BBadge,
        BCard,
        BCardText,
        vSelect,
        flatPickr,
        BFormFile,
        BCard,
        BCardHeader,
        BCardBody,
        BAvatar,
        BListGroup,
        BListGroupItem,
        BForm,
        BFormGroup,
        BMedia,
        BFormInput,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BRow,
        BCol,
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
    },
    data() {
        return {
            base64 : null,
            processing : false
        }
    },
    
    computed: {
        expire_display: function () {
            return moment(this.data.expire).format('DD MMMM ,YYYY')
        },
        user_limit: function () {
            if (this.data.account.user_limit < 1) {
                return "Unlimit ";
            } else {
                return this.data.account.user_limit;
            }
        }
    },
    props: {
        data : {
        required: true,
        },
    },
    created() {
        console.log(this.account)
    },
    watch: {
        "data.account.new_logo" : function (newVal, oldVal) {
           
            let reader = new FileReader();
            reader.readAsDataURL(newVal);
            reader.onload = evt => {
                let img = new Image();
                img.onload = () => {
                    if (img.width != 400 || img.height != 132) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                            title: 'Error',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: 'Logo must be 400px width 132px height',
                            },
                        })
                        this.data.account.new_logo = null
                        this.base64 = null
                    }
                this.imageLoaded = true;
                }
                img.src = evt.target.result;
            }
            if(newVal) {
                this.createBase64Image(newVal);
            } else {
                this.base64 = null;
            }
            }
    },
    methods: {
        createBase64Image: function(FileObject) {
            const reader = new FileReader();
            
            reader.onload = (event) => {
                this.base64 = event.target.result;
            }
            reader.readAsDataURL(FileObject);
        },
        save() {
        this.processing = true
        this.data.account.base64 = this.base64
      this.$http.post(process.env.VUE_APP_API_BASE+`/account/?page=basic`, this.data.account )
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
        },
        to_true_false(value) {
            if (value == "Y" || value == "TRUE") {
                return true
            } else {
                return false
            }
        }, 
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
