<template>
    <div>
    
        <b-row>
            <b-col md="12" lg="12">
                <div class="d-flex">
                    <feather-icon icon="UserIcon" size="19" />
                    <h4 class="mb-0 ml-50">
                        {{ $t('Terms And Conditions') }}
                    </h4>
                </div>
                <!-- Form: Personal Info Form -->
                <b-form class="mt-1">
                    <b-row>
                        
                        <b-col cols="12" md="12" lg="12">
                            <b-form-group label="Invoice Terms 收據條款" class="mb-4">
                                <b-form-textarea
                                    v-model="data.account.invoice_terms"
                                    placeholder="Invoice Terms 收據條款"
                                    rows="3"
                                    max-rows="20"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="12" lg="12">
                            <b-form-group label="Package Terms 套票條款" class="mb-4">
                                <b-form-textarea
                                    v-model="data.account.pt_terms"
                                    placeholder="Package Terms 套票條款"
                                    rows="3"
                                    max-rows="20"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="12" lg="12">
                            <b-form-group label="Membership Terms 會籍條款">
                                <b-form-textarea
                                    v-model="data.account.membership_terms"
                                    placeholder="Membership Terms 會籍條款"
                                    rows="3"
                                    max-rows="20"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
    
                    <b-row class="mt-2 mb-2">
                        <b-col>
                            <b-button :disabled="processing" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="save" v-if="$can('read', 'admin')">
                                Save Changes
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
          
        </b-row>
    </div>
    </template>
    
    <script>
    import {
        BFormTextarea,
        BCardText,
        BAlert,
        BLink,
        BCard,
        BCardHeader,
        BFormFile,
        BCardBody,
        BMedia,
        BFormCheckbox,
        BAvatar,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        BTab,
        BTabs,
        BListGroup,
        BListGroupItem,
        BBadge
    } from 'bootstrap-vue'
    import {
        ref
    } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import router from '@/router'
    import moment from 'moment'
    
    
    export default {
        components: {
            ToastificationContent,
            BFormTextarea,
            BFormCheckbox,
            BBadge,
            BCard,
            BCardText,
            vSelect,
            flatPickr,
            BFormFile,
            BCard,
            BCardHeader,
            BCardBody,
            BAvatar,
            BListGroup,
            BListGroupItem,
            BForm,
            BFormGroup,
            BMedia,
            BFormInput,
            BFormRadioGroup,
            BFormCheckboxGroup,
            BButton,
            BRow,
            BCol,
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
        },
        data() {
            return {
               
                processing : false
            }
        },
        
        computed: {
          
        },
        props: {
            data : {
                required: true,
            },
        },
        created() {
            
        },
        
        methods: {
           
            save() {
                this.processing = true
                this.data.account.base64 = this.base64
                this.$http.post(process.env.VUE_APP_API_BASE+`/account/?page=tnc`, this.data.account )
                .then(res => {
                if (res.data.result === true) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-center',
                        props: {
                        title: 'Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: res.data.message,
                        },
                    })
                    this.processing = false
                    } else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-center',
                        props: {
                        title: 'Error',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: res.data.message,
                        },
                    })
                    this.processing = false
                    }
                })
            },
            
        },
    }
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    </style>
    