import { render, staticRenderFns } from "./Tabtnc.vue?vue&type=template&id=3d58de08"
import script from "./Tabtnc.vue?vue&type=script&lang=js"
export * from "./Tabtnc.vue?vue&type=script&lang=js"
import style0 from "./Tabtnc.vue?vue&type=style&index=0&id=3d58de08&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports